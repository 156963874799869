<template>
  <!-- begin::MyOwnProfile for non-Superadmin Role -->
  <div v-if="isAuthenticated">
    <div
      v-if="!embedded && RoleID !== this.rolesList.ID_OBSERVER"
      class="manager-title"
    >
      <div>
        {{
          embedded
            ? $t("MY_PROFILE.TITLE")
            : $t("MY_COMPANY.EDIT_ADMIN_USER_TITLE")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div :class="embedded ? '' : 'white-box shadow-box'">
      <div class="row">
        <div class="manager-subtitle">
          {{
            embedded || RoleID === this.rolesList.ID_OBSERVER
              ? $t("MY_PROFILE.SUBTITLE")
              : $t("MY_COMPANY.EDIT_ADMIN_USER_SUBTITLE")
          }}
        </div>
      </div>

      <v-form
        class="form"
        ref="formMyOwnProfile"
        v-model="Valid"
        lazy-validation
      >
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.EMAIL") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Email"
              :placeholder="$t('MY_PROFILE.EMAIL')"
              readonly
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4 text-right">
            <v-avatar class="myOwnAvatar">
              <v-img :src="NewAvatarFile ? NewAvatarFile : InitialAvatarUrl" />
            </v-avatar>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.AVATAR") }}:
              <span class="font-weight-normal">
                {{ InitialAvatarFilename }}
              </span>
            </label>
            <input
              type="file"
              ref="inputFileAvatar"
              style="display: none"
              @change="changeAvatar($event.target.files)"
              accept=".jpg,.jpeg,.png,.gif"
            />
            <button
              class="btn button-filter btn-pill btn-primary mb-3 d-block"
              @click.prevent="$refs.inputFileAvatar.click()"
            >
              <inline-svg src="/media/icons/foto.svg" class="mr-3" />
              {{ $t("MY_PROFILE.CHANGE_AVATAR") }}
            </button>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_CURRENT") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="Fields.PasswordCurrent"
              :placeholder="$t('MY_PROFILE.PASSWORD_ONLY_TO_CHANGE')"
              :rules="[rules.requiredIfPasswordNew]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_NEW") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="Fields.PasswordNew"
              :placeholder="$t('MY_PROFILE.PASSWORD_NEW')"
              :rules="[rules.emptyOrMin6]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_NEW_CONFIRM") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="Fields.PasswordConfirm"
              :placeholder="$t('MY_PROFILE.PASSWORD_NEW_CONFIRM')"
              :rules="[rules.confirm]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.FULLNAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Name"
              :placeholder="$t('MY_PROFILE.FULLNAME')"
              :rules="[rules.required]"
              required
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PHONE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Phone"
              :placeholder="$t('MY_PROFILE.PHONE')"
              :rules="[rules.required]"
              required
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/phone-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>

        <div class="row button-row">
          <router-link
            v-if="!embedded"
            :to="
              RoleID === this.rolesList.ID_OBSERVER
                ? '/manager/home'
                : '/manager/myprofile'
            "
            v-slot="{ navigate }"
            custom
          >
            <button
              class="btn btn-pill btn-white button-form button-form-primary mr-9"
              @click="navigate"
            >
              <inline-svg
                src="/media/icons/arrow-return-primary.svg"
                class="mr-3"
              />
              {{ $t("GENERIC.BACK") }}
            </button>
          </router-link>

          <button
            ref="formMyOwnProfileSubmit"
            class="btn btn-pill btn-tertiary button-form"
            :disabled="!Valid"
            @click="onSubmitProfile"
          >
            {{ $t("GENERIC.SAVE") }}
          </button>
        </div>
      </v-form>
    </div>
  </div>
  <!-- end::MyOwnProfile for non-Superadmin Role -->
</template>

<style scoped>
.myOwnAvatar {
  border: 1px solid #cccccc;
  width: 72px !important;
  height: 72px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import { CHANGE_NAME, CHANGE_AVATAR } from "@/core/services/store/auth.module";
import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "MyOwnProfile",
  computed: {
    ...mapGetters(["isAuthenticated", "rolesList"])
  },
  data() {
    return {
      Valid: true,
      InitialAvatarUrl: "",
      InitialAvatarFilename: "",
      NewAvatarFile: null,
      RoleID: 0,
      Fields: {
        Email: "",
        Name: "",
        Phone: "",
        Avatar: null,
        PasswordCurrent: "",
        PasswordNew: "",
        PasswordConfirm: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        emptyOrMin6: value => {
          if (!value || value.length === 0 || value.length >= 6) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.MIN_LENGTH_FIELD") + " 6";
          }
        },
        confirm: value =>
          value === this.Fields.PasswordNew ||
          this.$i18n.t("MY_PROFILE.PASSWORD_MISMATCH"),
        requiredIfPasswordNew: value => {
          if (
            !this.Fields.PasswordNew ||
            this.Fields.PasswordNew.length === 0 ||
            !!value
          ) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.REQUIRED_FIELD");
          }
        }
      }
    };
  },
  props: {
    embedded: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.loadMyOwnProfileData(true);
  },
  methods: {
    loadMyOwnProfileData(firstLoad) {
      if (firstLoad) {
        // Show page loader
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      }

      UserService.getCurrentUserProfile().then(response => {
        this.RoleID = response.RoleID;
        response.RoleID = null;

        this.InitialAvatarUrl = response.Avatar;
        let avatarParts =
          response.Avatar.length > 0 ? response.Avatar.split("/") : [];
        this.InitialAvatarFilename =
          avatarParts.length > 0 ? avatarParts[avatarParts.length - 1] : "-";

        response.Avatar = null;
        this.Fields = response;

        if (firstLoad) {
          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }

        this.$store.dispatch(CHANGE_NAME, this.Fields.Name);
        this.$store.dispatch(CHANGE_AVATAR, this.InitialAvatarUrl);
      });
    },

    changeAvatar(newPhoto) {
      if (newPhoto) {
        // Might re-check MIME-type, but must rely on file extension, easy to tamper - check on Laravel side
        this.Fields.Avatar = newPhoto[0];
        this.InitialAvatarFilename = newPhoto[0]["name"];

        const reader = new FileReader();
        reader.onload = event => {
          this.NewAvatarFile = event.target.result;
        };
        reader.readAsDataURL(newPhoto[0]);
      }
    },

    onSubmitProfile(e) {
      e.preventDefault();

      if (this.$refs.formMyOwnProfile.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send update request
        UserService.updateCurrentUserProfile(this.Fields).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 412) {
              whyError = this.$i18n.t("MY_PROFILE.ERROR_412");
            } else if (statusCode === 428) {
              whyError = this.$i18n.t("MY_PROFILE.ERROR_428");
            } else if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("GENERIC.SAVE_OK"),
              variant: "success",
              solid: true
            });

            this.loadMyOwnProfileData(false);
          }

          // Reset submit button
          this.Valid = true;
          this.Fields.PasswordCurrent = "";
          this.Fields.PasswordNew = "";
          this.Fields.PasswordConfirm = "";
        });
      }
    }
  }
};
</script>
